import React, { useEffect, useRef } from "react";
import ContactFormComponent from "../contactForm/ContactForm";
import EnterpriseTitleComponent from "./PlatformTitleComponent";
import EnterpriseGenerateModelComponent from "./PlatformGenerateModelComponent";
import GlowingIconComponent from "../glowingIcon/GlowingIconComponent";
import { StylesContent } from "@/styles/styledComponents";
import BoxsComponent from "../boxsComponent/BoxsComponent";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { useScrollContext } from "@/hooks/ScrollContext";
import ScrollToTopButton from "../Library/ScrollToTopButton";

const EnterpriseComponent: React.FC = () => {

  const { setTargetRef } = useScrollContext();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTargetRef(containerRef); 
  }, [setTargetRef]);


  return (
    <StylesContent ref={containerRef}>
      <Navbar />
      <EnterpriseTitleComponent />
      <EnterpriseGenerateModelComponent />
      <BoxsComponent />
      <GlowingIconComponent />
      <ContactFormComponent />
      <Footer />
      <ScrollToTopButton/>
    </StylesContent>
  );
};

export default EnterpriseComponent;
