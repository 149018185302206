import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavbarContainer = styled.nav`
  width: 100%;
  max-width: 1440px;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: black;
  position: sticky;
  top: 16px;
  right: 32px;
  z-index: 1000;
  color: white;
  gap: 32px;
  border-radius: 8px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 34.46%,
      rgba(255, 255, 255, 0.1) 180.94%
    ),
    url(Wrinkled-Glass-Texture.jpg);
  box-shadow: inset -1px 2px 4px rgba(255, 255, 255, 0.25);
  // filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(9px);

  @media (max-width: 768px) {
    height: 56px;
    padding: 0 10px;
    justify-content: space-between;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavLinks = styled.div<{ open: boolean }>`
  display: flex;
  gap: 32px;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ open }) => (open ? "flex" : "none")};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: black;
    flex-direction: column;
    padding: 10px 0;
    gap: 10px;
  }
`;

export const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1em;
  position: relative;

  &:hover {
    color: #0070f3;
  }

  &.active {
    font-weight: bold;
  }
`;

export const ActiveIndicator = styled.div`
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 2px;
  border-bottom: 3px solid #594DED;
filter: drop-shadow(0px 7px 10px rgba(89, 77, 237, 0.8)) drop-shadow(0px 1px 4px #594DED);
`;

export const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: white;

  @media (max-width: 768px) {
    display: block;
  }
`;
/* Frame 1261155418 */

