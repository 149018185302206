import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import * as THREE from 'three'
export default function BackgroundControls() {
  const {scene, camera, viewport } = useThree();


  useEffect(() => {
    scene.background = new THREE.Color('#111111');
  }, [scene,viewport,camera]);

  return null;
}
