import styled from "styled-components";

export const StylesContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 144px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1508px;
  min-height: 900px;

  min-height: auto;
  @media (max-width: 1508px) {
    max-width: 100%;
    padding:18px;

  }
  
  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 12px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 8px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    padding: 4px;
  }
`;

export const HomePageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-left: 20px;
  width: 100%;
  height: 100%;
  background-color: aquamarine;
`;

export const HomeHeading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

export const SubHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const Button = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 1rem;
  cursor: pointer;
`;

export const BoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
`;

export const Box = styled.div`
  background: #f3f3f3;
  padding: 20px;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

// Navbar component styling
export const NavbarWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 20px;
  z-index: 10;
  color: #fff;
`;

export const NavButton = styled.button`
  background-color: #ff9800;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e68900;
  }
`;

// Wrapper for the Three.js scene container
export const SceneContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
  pointer-events: auto;

  canvas {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

// Content container for each page
export const PageContent = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  z-index: 1;
`;

// Heading styles for each page
export const PageHeading = styled.h1`
  font-size: 3rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
`;

// Contact form styling
export const ContactFormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  margin-bottom: 20px;
`;

export const ContactInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const ContactButton = styled.button`
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

// General HTML element embedded within Three.js scene
export const ThreeElementWrapper = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
`;

// Shared button style for all pages
export const SharedButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  background: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #218838;
  }
`;

export const PricingPageWrapper = styled(PageWrapper)`
  background: linear-gradient(to bottom, #134e5e, #71b280);
`;

export const EnterpriseHeading = styled(PageHeading)`
  color: #ffffff;
`;

export const PricingHeading = styled(PageHeading)`
  color: #ffffff;
`;
