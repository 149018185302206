import { throttle } from "lodash";
import React, { createContext, useContext, useState, useRef, useEffect } from "react";

type ScrollContextType = {
  scrollProgress: number;
  setScrollProgress: React.Dispatch<React.SetStateAction<number>>;
  targetRef: React.RefObject<HTMLElement> | null;
  setTargetRef: React.Dispatch<React.SetStateAction<React.RefObject<HTMLElement> | null>>;
};

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [targetRef, setTargetRef] = useState<React.RefObject<HTMLElement> | null>(null);


  useEffect(() => {
    if (!targetRef || !targetRef.current) return;
  
    const handleScroll = throttle(() => {
      const element = targetRef.current;
      if (!element) return;
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight - element.clientHeight;
      const progress = scrollTop / scrollHeight;
      setScrollProgress(progress);
    }, 10); 
  
    const element = targetRef.current;
    element.addEventListener("scroll", handleScroll);
    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [targetRef]);
  

  return (
    <ScrollContext.Provider value={{ scrollProgress, setScrollProgress, targetRef, setTargetRef }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScrollContext = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScrollContext must be used within a ScrollProvider");
  }
  return context;
};
