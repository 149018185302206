import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { DirectionalLight, AmbientLight } from 'three';

export default function LightControlsHome() {
  const { scene, size } = useThree();

  useEffect(() => {
    const ambientLight = new AmbientLight(0xffffff, 1); 
    const directionalLight = new DirectionalLight(0xFFDCC4, 1);
    directionalLight.position.set(10, 10, 5);
    const directionalLightFiller = new DirectionalLight(0x594ded, 3);
    directionalLight.position.set(-20, 10, 5);

    scene.add(ambientLight);
    scene.add(directionalLight);
    scene.add(directionalLightFiller);

    const handleMouseMove = (event: MouseEvent) => {
      const x = (event.clientX / size.width) * 2 - 1;
      const y = -(event.clientY / size.height) * 2 + 1;

      directionalLight.position.set(x * 10, y * 10, 5);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      scene.remove(ambientLight);
      scene.remove(directionalLight);
    };
  }, [scene, size]);

  return null;
}
