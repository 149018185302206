import React, { useEffect, useRef } from "react";
import ContactFormComponent from "../contactForm/ContactForm";
import HomeTitleComponent from "./HomeTitleComponent";
import GlowingIconComponent from "../glowingIcon/GlowingIconComponent";
import { StylesContent } from "@/styles/styledComponents";
import BoxsComponent from "../boxsComponent/BoxsComponent";
import HomeInteractiveComponent from "./HomeInteractiveComponent";
import HomeAiModelingComponent from "./HomeAiModelingComponent";
import HomePlatformComponent from "./HomePlatformComponent";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { useScrollContext } from "@/hooks/ScrollContext";
import ScrollToTopButton from "../Library/ScrollToTopButton";
import LogoChain from "./LogoChain";

const HomeComponents: React.FC = () => {

  const { setTargetRef } = useScrollContext();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTargetRef(containerRef); 
  }, [setTargetRef]);


  return (
    <StylesContent ref={containerRef}>
      <Navbar />
      <HomeTitleComponent />
      <LogoChain />
      <HomeInteractiveComponent />
      <HomeAiModelingComponent />
      <BoxsComponent home={true} />
      <GlowingIconComponent home={true} />
      <HomePlatformComponent />
      <ContactFormComponent />
      <Footer />
      <ScrollToTopButton/>
    </StylesContent>
  );
};

export default HomeComponents;
