import React, { useEffect, useRef, useState, useMemo, Suspense } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useScrollContext } from "@/hooks/ScrollContext";
import { throttle } from "lodash";

function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

function Models() {
  const Rose = useLoader(GLTFLoader, "/twin_flame.glb");
  const ring = useLoader(GLTFLoader, "/gold_rings.glb");
  const balenciaga = useLoader(GLTFLoader, "/balenciaga_track_3_white.glb");
  const { scrollProgress } = useScrollContext();

  const modelRef1 = useRef<THREE.Object3D | null>(null);
  const modelRef2 = useRef<THREE.Object3D | null>(null);
  const modelRef3 = useRef<THREE.Object3D | null>(null);

  const [mousePosition, setMousePosition] = useState(new THREE.Vector3(0, 0, 0));
  const [mousePosition2, setMousePosition2] = useState(
    new THREE.Vector3(0, 0, 0)
  );

  const handleMouseMove = useMemo(
    () =>
      throttle((event: MouseEvent) => {
        const { innerWidth, innerHeight } = window;
        const x = (event.clientX / innerWidth) * 2 - 1;
        const y = -(event.clientY / innerHeight) * 2 + 1;
        setMousePosition(new THREE.Vector3(x * 15, y * 15, 0));
        setMousePosition2(new THREE.Vector3(x * 5, y * 5, 0));
      }, 10),
    []
  );

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  useEffect(() => {
    const setupModel = (scene: THREE.Group, position: THREE.Vector3, scale: number, rotation?: THREE.Euler) => {
      scene.scale.set(scale, scale, scale);
      scene.position.copy(position);
      if (rotation) scene.rotation.copy(rotation);
    };

    if (Rose.scene) setupModel(Rose.scene, new THREE.Vector3(40, 5, -30), 3);
    if (ring.scene) {
      setupModel(
        ring.scene,
        new THREE.Vector3(-45, 10, -30),
        0.7,
        new THREE.Euler(degreesToRadians(0), degreesToRadians(0), degreesToRadians(0))
      );
    }
    if (balenciaga.scene) {
      setupModel(
        balenciaga.scene,
        new THREE.Vector3(-4, -1, 27.5),
        0.8,
        new THREE.Euler(degreesToRadians(0), degreesToRadians(70), degreesToRadians(0))
      );
    }

    return () => {
      const disposeScene = (scene: THREE.Group) => {
        scene.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            if (child.geometry) child.geometry.dispose();
            if (Array.isArray(child.material)) {
              child.material.forEach((mat) => mat.dispose());
            } else if (child.material) {
              child.material.dispose();
            }
          }
        });
      };

      if (Rose.scene) disposeScene(Rose.scene);
      if (ring.scene) disposeScene(ring.scene);
      if (balenciaga.scene) disposeScene(balenciaga.scene);
    };
  }, [Rose, ring, balenciaga]);

  const modelPositions = useMemo(() => ({
    model1X: 40 + scrollProgress * 150,
    model2X: -45 + scrollProgress * 300,
    model2Y: 10 + scrollProgress * 300,
    model3X: -2 - scrollProgress * 10,
    model1RotationY: 5 + scrollProgress * Math.PI * 4,
    model2RotationY: 10 + scrollProgress * Math.PI * 4,
    model3RotationY: -1 + scrollProgress * Math.PI * 4,
  }), [scrollProgress]);
  

  useFrame(() => {
    if (modelRef1.current) {
      modelRef1.current.lookAt(mousePosition);
      modelRef1.current.position.x = modelPositions.model1X;
      modelRef1.current.rotation.y = modelPositions.model1RotationY;
    }
    if (modelRef2.current) {
      modelRef2.current.lookAt(mousePosition);
      modelRef2.current.position.x = modelPositions.model2X;
      modelRef2.current.position.y = modelPositions.model2Y;
      modelRef2.current.rotation.y = modelPositions.model2RotationY;
    }
    if (modelRef3.current) {
      modelRef3.current.lookAt(mousePosition2);
      modelRef3.current.position.x = modelPositions.model3X;
      modelRef3.current.rotation.y = modelPositions.model3RotationY;
    }
  });
  

  return (
    <>
      <Suspense fallback={null}>
        {Rose.scene && <primitive object={Rose.scene} ref={modelRef1} />}
        {ring.scene && <primitive object={ring.scene} ref={modelRef2} />}
        {balenciaga.scene && <primitive object={balenciaga.scene} ref={modelRef3} />}
      </Suspense>
    </>
  );
}

export default Models;
