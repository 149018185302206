import styled from "styled-components";
import Text from "@/components/Library/text/Text";
import { FontFamily, FontWeight, TextSize } from "@/constants/text";
import { TextColor } from "@/constants/colors";
import { IconName } from "@/constants/icon";
import React from "react";
import BoxGlowingIconComponent from "./BoxGlowingIconComponent";
import { SectionContainer } from "@/styles/styledComponents";
import { useDevice } from "@/hooks/DeviceContext";

interface BoxData {
  title: string;
  body: string;
  iconName: any;
}

const HomeList: BoxData[] = [
  {
    title: "80% faster Technology ",
    body: "Reduce development time by 80% with cutting-edge technology creating interactive 3D environments.",
    iconName: IconName.FASTFORWARDCIRCLE,
  },
  {
    title: "Professional creative team",
    body: "Experts will design your webspace to reflect your brand identity and deliver a unique experience.",
    iconName: IconName.USERSTHREE,
  },
  {
    title: "Advanced 3D UX Solutions",
    body: "Designed with a user-first approach, our products ensure seamless, engaging, and interactive experiences",
    iconName: IconName.LAYOUT,
  },
  {
    title: "Smooth transition",
    body: "Tailored workflows create smooth transitions and integrations for stores, landing pages, and more!",
    iconName: IconName.PATH,
  },
];

const EnterpriceList: BoxData[] = [
  {
    title: "Creative Agencies",
    body: "Build dynamic 3D environments that tell a story, enhancing branding, marketing, and product launches.",
    iconName: IconName.FLOWERTULIP,
  },
  {
    title: "E-commerce Businesses",
    body: "Engage customers longer, drive conversions, and elevate your brand with immersive 3D web experiences.",
    iconName: IconName.SHOPPINGBAG,
  },
  {
    title: "Artists & Creators",
    body: "Showcase your work in a 3D interactive gallery, offering visitors an immersive way to explore your creativity.",
    iconName: IconName.PAINTBRUSH,
  },
  {
    title: "Professional Sites",
    body: "Design visually compelling 3D websites to impress clients, partners, and potential collaborators.",
    iconName: IconName.PROJECTORSCREEN,
  },
];


export const GlowingIconStylesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  padding: 32px;
  flex-shrink: 0;
  min-height: 900px;

  @media (max-width: 1024px) {
    gap: 48px;
    padding: 24px;
  }

  @media (max-width: 768px) {
    gap: 32px;
    padding: 16px;
  }
`;


export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 16px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;



export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 80px;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 20px;


  /* @media (max-width: 1508px) {
    height: 672px;
  } */

  @media (max-width: 1350px) {
    max-width: 700px;
  }
  @media (max-width: 1024px) {
    padding: 12px;
  }

  @media (max-width: 768px) {
    gap: 16px;
    margin-bottom: 16px;  }

  @media (max-width: 480px) {
    padding: 4px;
  }
`;

interface GlowingIconComponentProps {
  home?: boolean;
}

const GlowingIconComponent: React.FC<GlowingIconComponentProps> = ({ home }) => {
  const listData = home ? HomeList : EnterpriceList;
  const { deviceType } = useDevice();

  return (
    <SectionContainer>
      <GlowingIconStylesContent>
      <TextContainer>
        <Text
          size={ deviceType !== "mobile" ? TextSize.D1:TextSize.H1}
          $family={FontFamily.Poppins}
          $weight={FontWeight.SEMI_BOLD}
          color={TextColor.TEXT_ON_PRIMARY}
        >
          {home ? "Why Mocart?" : "Who is it for?"}
        </Text>
      </TextContainer>
        <ItemsContainer>
          {listData.map((item, index) => (
            <BoxGlowingIconComponent
              key={index}
              title={item.title}
              body={item.body}
              iconName={item.iconName}
            />
          ))}
        </ItemsContainer>
      </GlowingIconStylesContent>
    </SectionContainer>
  );
};

export default GlowingIconComponent;
