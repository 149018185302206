import React from "react";
import { FontWeight, TextSize } from "@/constants/text";
import { TextColor } from "@/constants/colors";
import Text from "../Library/text/Text";
import styled, { css } from "styled-components";

export const Title = styled.div`
  color: inherit;
  line-height: 24px;
  text-transform: capitalize;
`;

export const Description = styled.div`
  color: inherit;
  line-height: 24px;
`;

interface BoardContainerProps {
  $isIntractiv: boolean;
}
export const BoardContainer = styled.div<BoardContainerProps>`
  align-self: stretch;
  display: flex;
  width: ${({ $isIntractiv: isIntractiv }) =>
    isIntractiv ? "258px" : "336px"};
  /* max-height: 158px; */
  transition: all 0.3s ease;
border-left: 1px solid;
border-image-source: ${"linear-gradient(to bottom, #ffffff, #ffffff)"};
border-image-slice: 1;
/* background-color: blanchedalmond; */
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: left;
  padding-left: 16px;
  gap: 16px;
`;

export enum BoxSize {
  XS = "xs",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

interface BoardProps {
  title: string;
  body: string;
  isIntractiv: boolean;
}
const BoxInteractiveComponent: React.FC<BoardProps> = ({
  title,
  body,
  isIntractiv,
}) => {
  return (
    <BoardContainer $isIntractiv={isIntractiv}>
      <TextWrapper>
        <Text
          size={TextSize.H2}
          $weight={FontWeight.BOLD}
          color={TextColor.TEXT_ON_PRIMARY}
        >
          {title}
        </Text>
        <Text
          size={TextSize.H3}
          $weight={FontWeight.LIGHT}
          color={TextColor.SECONDARY_ON_DARK}
        >
          {body}
        </Text>
      </TextWrapper>
    </BoardContainer>
  );
};

export default BoxInteractiveComponent;
