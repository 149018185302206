export enum BackgroundColor {
    PRIMARY_BACKGROUND = '#FFFFFF',
    SECONDARY_BACKGROUND = '#ECEDF5',
    PRIMARY_BACKGROUND_HOVER = '#636363',
    DISABLED_BACKGROUND = '#ECEDF5',
    INVERTED_COLOR_BACKGROUND = '#323338',
    GREY_BACKGROUND = '#F5F6F8',
    ALL_GREY_BACKGROUND = '#F5F6F8',
    PRIMARY_SELECTED_COLOR = '#DEDCFF',

}

export enum BorderColor {
    UI_BORDER = '#C5C7D0',
    DIVIDER = '#E6E6E6',
    LAYOUT_BORDER = '#E6E9EF',



    POSITIVE = "#147B6F",
    NEGATIVE = "#FF474A",
} 

export enum SemanticColors {
    TRANSPARENT = 'transparent',
    PRIMARY = "#594DED",
    POSITIVE = "#147B6F",
    NEGATIVE = "#FF474A",
    WARNING = "#FFC247",
    PRIVATE = "#F65F7C",
    PRIMARY_HOVER = "#2012BA",
    POSITIVE_HOVER = "#0E5852",
    NEGATIVE_HOVER = "#8F0002",
    WARNING_HOVER = "#F5A300",
    SHAREABLE = "#A358DF",
    PRIMARY_SELECTED = "#CAC7FA",
    POSITIVE_SELECTED = "#CAF6F1",
    NEGATIVE_SELECTED = "#FFC2C3",
    WARNING_SELECTED = "#FFEBC2",
    DISABLED_BACKGROUND = "#ECEDF5",
    ICON_COLOR = "#676879",
    SOOGL_BUTTON = "#1877F2",
    PRIMARY_DISABLED = "#A9A5F2",
    POSITIVE_DISABLED = "#8CCCC5",
    NEGATIVE_DISABLED = "#FFB3B5",
    WARNING_DISABLED = "#FFE1A3",
}

export enum TextColor {
    POSITIVE = '#147B6F',
    NEGATIVE = '#FF474A',
    PRIMARY_TEXT = '#323338',
    SECONDARY_TEXT = '#676879',
    TEXT_ON_INVERTED = '#FFFFFF',
    TEXT_ON_PRIMARY = '#FFFFFF',
    DISABLED_TEXT = '#67687966',
    PLACEHOLDER = '#676879',
    LINK = '#1877F2',
    BLACK = '#000000',
    PRIMARY = "#594DED",
    SECONDARY_ON_DARK = '#AFAFAF',
}

export enum IconColor {
    ICONCOLOR = '#676879',
    PRIMARY = '#323338',
    SECONDARY = '#676879',
    SUCCESS = '#0C8D0A',
    WARNING = '#FFCB00',
    DANGER = '#E44258',
    INFO = '#0073EA',
    LIGHT = '#FFFFFF',
    DARK = '#000000',
    DISABLED = '#67687966',
}

