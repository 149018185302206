import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.nav`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  bottom: 0;
  color: white;
  gap: 20px;
  justify-content: space-between;
margin-bottom: 40px;

background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 180.46%,
      rgba(0, 0, 0, 0.8) 34.94%
    ),
    url(Wrinkled-Glass-Texture.jpg);
  box-shadow: inset -1px 0px 4px rgba(255, 255, 255, 0.25);
  // filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(9px);

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    height: 56px;
    padding: 0 10px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 50%;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    background-color: black;
    padding: 10px 0;
    gap: 10px;
  }
`;

export const FooterText = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 50%;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    background-color: black;
    padding: 10px 0;
    gap: 10px;
  }
`;

export const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1em;
  position: relative;

  &:hover {
    color: #0070f3;
  }

  &.active {
    font-weight: bold;
  }
`;


export const ActiveIndicator = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  border-bottom: 3px solid #594ded;
  filter: drop-shadow(0px 7px 10px rgba(89, 77, 237, 0.8))
    drop-shadow(0px 1px 4px #594ded);
`;

export const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: white;

  @media (max-width: 768px) {
    display: block;
  }
`;
/* Frame 1261155418 */
