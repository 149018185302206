import axios from 'axios';

const instance = axios.create({
    baseURL: "https://server-cloud-run-service-kruirvrv6a-uc.a.run.app", 
    timeout: 300000, 
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
