import React from "react";
import Text from "@/components/Library/text/Text";
import { FontWeight, TextSize } from "@/constants/text";
import { TextColor } from "@/constants/colors";
import BoxInteractiveComponent from "./BoxInteractiveComponent";
import styled from "styled-components";
import { SectionContainer } from "@/styles/styledComponents";
import { Canvas } from "@react-three/fiber";
import InteractivModel from "@/threejs/InteractivModel";
import { useDevice } from "@/hooks/DeviceContext";

interface BoxData {
  title: string;
  body: string;
}

const HomeList: BoxData[] = [
  {
    title: "Interactive Experiences",
    body: "Let your customers zoom, rotate, and explore your products like never before.",
  },
  {
    title: "Effortless Integration",
    body: "Simple to embed, fully compatible with any website platform.",
  },
  {
    title: "AI-Powered Model Creation",
    body: "Transform 2D images into detailed 3D models using advanced AI technology.",
  },
  {
    title: "Perfected by Human Experts",
    body: "Professional corrections ensure every model is flawless and true-to-life.",
  },
];
export const HomeInteractiveStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 34.46%,
      rgba(255, 255, 255, 0.2) 104.94%
    ),
    url(Wrinkled-Glass-Texture.jpg);
  box-shadow: inset -1px 2px 4px rgba(255, 255, 255, 0.25);
  filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(9px);
`;

export const SceneContainer = styled.div`
  position: absolute;
  width: 800px;
  height: 600px;
  overflow: hidden;
  right: 200px;
  z-index: 1;
  @media (max-width: 1708px) {
    height: auto;
    right: 50px;
  }

  @media (max-width: 1508px) {
    width: 700px;
    height: 600px;
    right: 50px;
  }

  @media (max-width: 1444px) {
    width: 500px;
    height: 400px;
    order: -1;
    right: 0;
    position: relative;
    gap: 64px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-shrink: 0;
  width: 100%;
  
  @media (max-width: 1444px) {
    flex-direction: column;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    gap: 16px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  gap: 24px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    gap: 12px;
    max-width: 90%;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 56px;
  flex-shrink: 0;
  margin-bottom: 20px;
  max-width: 800px;

  @media (max-width: 1444px) {
    width: 100%;
    padding: 24px;
  }
  
  @media (max-width: 900px) {
    padding: 12px;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    gap: 80px;
  }
`;

export const Container = styled.div`
  width: 1508px;
  max-width: 100%;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 64px;
  padding: 62px;
  @media (max-width: 1024px) {
    gap: 48px;
  }

  @media (max-width: 768px) {
    gap: 32px;
  }

  @media (max-width: 480px) {
    gap: 24px;
  }
`;

const HomeInteractiveComponent: React.FC = () => {
  const { deviceType } = useDevice();


  
  return (
    <SectionContainer>
      <HomeInteractiveStyle>
        <Container>
          <TextContainer>
            <Text
              size={deviceType === "mobile" ? TextSize.H1 : TextSize.D1}
              $weight={FontWeight.BOLD}
              color={TextColor.TEXT_ON_PRIMARY}
            >
              Embed interactive realistic 3D Models directly into your website{" "}
            </Text>
            <Text
              size={deviceType === "mobile" ? TextSize.H3 : TextSize.H3}
              $weight={FontWeight.LIGHT}
              color={TextColor.SECONDARY_ON_DARK}
            >
              A revolutionary 3D product display solution to Elevate Your
              Product Showcase
            </Text>
          </TextContainer>
          <ContentWrapper>
            <SceneContainer>
              <Canvas shadows>
                {" "}
                <ambientLight intensity={0.3} />
                <directionalLight
                  position={[5, 10, 5]}
                  intensity={7}
                  color="white"
                  castShadow
                />
                <InteractivModel />
              </Canvas>
            </SceneContainer>
            <ItemsContainer>
              {HomeList.map((item, index) => (
                <BoxInteractiveComponent
                  key={index}
                  title={item.title}
                  body={item.body}
                  isIntractiv={true}
                />
              ))}
            </ItemsContainer>
          </ContentWrapper>
        </Container>
      </HomeInteractiveStyle>
    </SectionContainer>
  );
};

export default HomeInteractiveComponent;
