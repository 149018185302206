import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DeviceProvider } from "./hooks/DeviceContext";
import { ScrollProvider } from "./hooks/ScrollContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <DeviceProvider>
      <ScrollProvider>
        <App />
      </ScrollProvider>
    </DeviceProvider>
  </React.StrictMode>
);

reportWebVitals();
