// import React from "react";
// import styled, { keyframes } from "styled-components";

// export const SectionContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100vw;
// `;

// const scrollAnimation = keyframes//`
//   0% { background-position: 10 10; }
//   100% { background-position: -10000% ; } 
// `;

// const LogoChainContent = styled.div`
//   width: 100vw; 
//   height: 100px; 
//   background-image: url("/Frame6.png");
//   background-repeat: repeat-x; 
//   background-size: auto 90%; 
//   animation: ${scrollAnimation} 240s linear infinite; 
//   background-position: center;
// `;

// const LogoChain: React.FC = () => {
//   return (
//     <SectionContainer>
//       <LogoChainContent />
//     </SectionContainer>
//   );
// };

// export default LogoChain;

import React from "react";
import styled, { keyframes } from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-bottom: 144px;
`;

const scrollAnimation = keyframes`
  0% { background-position-x: 0; }
  100% { background-position-x: -5505px; } /* Adjust distance to fit the pattern's repeat length */
`;

const LogoChainContent = styled.div`
  width: 100vw; 
  height: 100px; 
  background-image: url("/Frame6.png");
  background-repeat: repeat-x; 
  background-size: auto 90%; 
  animation: ${scrollAnimation} 80s linear infinite; /* Adjust duration for desired speed */
`;

const LogoChain: React.FC = () => {
  return (
    <SectionContainer>
      <LogoChainContent />
    </SectionContainer>
  );
};

export default LogoChain;

