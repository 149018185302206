import React from "react";
import styled from "styled-components";
import Text from "../Library/text/Text";
import { FontFamily, FontWeight, TextSize } from "../../constants/text";
import Button from "../Library/button/Button";
import { SectionContainer } from "@/styles/styledComponents";
import { ButtonSize, ButtonType, ButtonVariant } from "@/constants/button";
import { TextColor } from "@/constants/colors";
import { useDevice } from "@/hooks/DeviceContext";


export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 64px;
  min-height: 900px;
  @media (max-width: 768px) {
    padding: 32px;
  }
`;

export const EnterpriseTitleStylesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  width: 532px;
  gap: 64px;

  @media (max-width: 768px) {
    width: 100%;
    gap: 24px;
  }
`;
interface ButtonProps {
  $mobile: boolean;
}
export const Buttons = styled.div<ButtonProps>`
  display: flex;
  flex-direction: ${({ $mobile: mobile }) => (mobile ? "column" : "row")};
  width: 100%;
  justify-content: left;
  align-items: center;
  gap: 32px;
`;

const EnterpriseTitleComponent: React.FC = () => {
  const { deviceType } = useDevice();

  return (
    <SectionContainer>
      <TitleContent>
        <EnterpriseTitleStylesContent>
          <Text
            color={TextColor.TEXT_ON_PRIMARY}
            $weight={FontWeight.BOLD}
            $family={FontFamily.Poppins}
            size={deviceType === "mobile" ? TextSize.D1 : TextSize.TT}
          >
            {"Build 3D\nweb\nspaces"}
          </Text>
          <Text
            color={TextColor.SECONDARY_ON_DARK}
            $weight={FontWeight.NORMAL}
            size={TextSize.H2}
          >
            {
              "Powerful editor for creating parameter\nbased environments\nNo code, no 3D skills, free, with AI."
            }
          </Text>
          <Buttons $mobile={deviceType === "mobile"}>
          <Button
              size={ButtonSize.LARGE}
              type={ButtonType.PRIMARY}
              variant={ButtonVariant.SECONDARY}
              text="Take me to a web space"
              fullWidth={deviceType === "mobile"}
              onClick={() => {
                window.location.href = "https://3d.mocart.io";
              }}
            />
            <Button
              size={ButtonSize.LARGE}
              type={ButtonType.PRIMARY}
              variant={ButtonVariant.PRIMARY}
              text="Get early access"
              fullWidth={deviceType === "mobile"}
              onClick={() => {
                const targetElement = document.getElementById("ContactForm");
                if (targetElement) {
                  targetElement.scrollIntoView({ behavior: "smooth" });
                }
              }}
            />
          </Buttons>
        </EnterpriseTitleStylesContent>
      </TitleContent>
    </SectionContainer>
  );
};

export default EnterpriseTitleComponent;
