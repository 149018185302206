import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';

export type DeviceType = 'mobile' | 'tablet' | 'desktop';

 interface DeviceContextType {
  deviceType: DeviceType;
  viewportWidth: number;
  viewportHeight: number;
}

const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

export const DeviceProvider = ({ children }: { children: ReactNode }) => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType(window.innerWidth));

  function getDeviceType(width: number): DeviceType {
    if (width < 768) return 'mobile';
    if (width >= 768 && width <= 1024) return 'tablet';
    return 'desktop';
  }

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
      setDeviceType(getDeviceType(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <DeviceContext.Provider value={{ deviceType, viewportWidth, viewportHeight }}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = (): DeviceContextType => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }
  return context;
};
