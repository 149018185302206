import  { useState } from "react";
import {  useLocation } from "react-router-dom";
import {
  NavbarContainer,
  NavLinks,
  MobileMenuButton,
  LogoContainer,
  NavLink,
  ActiveIndicator,
} from "./NavbarStyles";
import { ReactComponent as LogoIcon } from "./LogoIcon.svg";
import { TextSize, FontWeight, FontFamily } from "@/constants/text";
import { TextColor } from "@/constants/colors";
import Text from "@/components/Library/text/Text"

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <NavbarContainer>
      <LogoContainer>
        <LogoIcon />
      </LogoContainer>
      <MobileMenuButton onClick={toggleMenu}>
        {menuOpen ? "✖" : "☰"}
      </MobileMenuButton>
      <NavLinks onClick={() => setMenuOpen(false)} open={menuOpen}>
        <NavLink to="/" className={location.pathname === "/" ? "active" : ""}>
          <Text
            size={TextSize.H3}
            $family={FontFamily.Poppins}
            color={TextColor.TEXT_ON_PRIMARY}
          >
            Home
          </Text>
          {location.pathname === "/" && <ActiveIndicator />}
        </NavLink>
        <NavLink
          to="/enterprise"
          className={location.pathname === "/enterprise" ? "active" : ""}
        >
          <Text
            size={TextSize.H3}
            $family={FontFamily.Poppins}
            color={TextColor.TEXT_ON_PRIMARY}
          >
            Platform
          </Text>
          {location.pathname === "/enterprise" && <ActiveIndicator />}
        </NavLink>
      </NavLinks>
    </NavbarContainer>
  );
}
