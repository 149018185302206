
import React, { ChangeEvent, forwardRef } from "react";
import { FontFamily, FontWeight, TextSize } from "@/constants/text";
import {
  InputWrapper,
  LabelText,
  HelperText,
  StyledTextarea,
} from "./InputStyles";
import { TextColor } from "@/constants/colors";
import { InputMode, InputSize } from "@/constants/input";

type InputPropsCustom = {
  inputSize?: InputSize;
  mode?: InputMode;
  label?: string;
  helperText?: string;
  fullWidth?: boolean;
  height: number; // Changed to `number` to match the `$height` type in StyledTextarea
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void; // Changed to `HTMLTextAreaElement`
};

export type InputProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>, // Changed to `TextareaHTMLAttributes`
  "size"
> &
  InputPropsCustom;

const Textarea = forwardRef<HTMLTextAreaElement, InputProps>(
  (
    {
      inputSize = InputSize.MEDIUM,
      mode = InputMode.NORMAL,
      label,
      placeholder,
      helperText,
      value,
      onChange,
      height,
      fullWidth = true,
      ...props
    },
    ref
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => { // Changed to `HTMLTextAreaElement`
      onChange?.(e);
    };

    return (
      <InputWrapper $fullWidth={fullWidth}>
        {label && (
          <LabelText
            $family={FontFamily.Poppins}
            size={TextSize.TEXT2}
            $weight={FontWeight.NORMAL}
            color={
              mode === InputMode.DISABLED
                ? TextColor.DISABLED_TEXT
                : TextColor.PRIMARY_TEXT
            }
          >
            {label}
          </LabelText>
        )}
        <StyledTextarea
          $height={height}
          ref={ref}
          $size={inputSize}
          $mode={mode}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={mode === InputMode.DISABLED}
          $fullWidth={fullWidth}
          {...props}
        />
        {helperText && (
          <HelperText
            $family={FontFamily.Poppins}
            size={TextSize.TEXT2}
            $weight={FontWeight.NORMAL}
            color={
              mode === InputMode.ERROR
                ? TextColor.NEGATIVE
                : TextColor.SECONDARY_TEXT
            }
          >
            {helperText}
          </HelperText>
        )}
      </InputWrapper>
    );
  }
);

export default Textarea;
