

import React, { useEffect, useRef, useState, useMemo } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { Html, OrbitControls } from "@react-three/drei";
import styled from "styled-components";
import { useDevice } from "@/hooks/DeviceContext";
import { Environment } from "@react-three/drei";

const HoverText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  pointer-events: painted;
  transition: opacity 0.3s ease;
`;

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

function InteractivModel() {
  const gltf = useLoader(GLTFLoader, "/bycicle.glb");
    const modelRef = useRef<THREE.Object3D | null>(null);
  const touchTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { deviceType } = useDevice();
  const [controlsEnabled, setControlsEnabled] = useState(
    deviceType !== "mobile"
  );

  const handleTouchStart = () => {
    touchTimerRef.current = setTimeout(() => {
      setControlsEnabled(true);
    }, 1000);
  };

  const handleTouchEnd = () => {
    if (touchTimerRef.current) {
      clearTimeout(touchTimerRef.current);
      touchTimerRef.current = null;
    }
    setControlsEnabled(false);
  };

  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.scale.set(0.27, 0.27, 0.27);
      gltf.scene.position.set(0, -1.9, 0);
      gltf.scene.rotation.set(
        degreesToRadians(0),
        degreesToRadians(250),
        degreesToRadians(0)
      );
      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
    }

    return () => {
      gltf.scene?.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.geometry.dispose();
          if (Array.isArray(child.material)) {
            child.material.forEach((mat) => mat.dispose());
          } else if (child.material) {
            child.material.dispose();
          }
        }
      });
    };
  }, [gltf]);

  useFrame(({ clock }) => {
    if (modelRef.current) {
      modelRef.current.position.y =
        -1.9 + Math.sin(clock.getElapsedTime()) * 0.1;
      modelRef.current.rotation.y += 0.001;
    }
  });

  return (
    <>
      {deviceType === "mobile" && (
        <Html>
          <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            {!controlsEnabled && <HoverText>Hold and Drag</HoverText>}
          </div>
        </Html>
      )}
      {controlsEnabled && (
        <OrbitControls enablePan={false} enableZoom={false} />
      )}
      <ambientLight intensity={0.5} />
      <directionalLight intensity={1} position={[5, 10, 5]} />
      <primitive object={gltf.scene} ref={modelRef} />
    </>
  );
}

export default InteractivModel;
