import { useEffect, useMemo, useRef } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useScrollContext } from "@/hooks/ScrollContext";
import LightControlsPlatform from "./LightControlsPlatform";

function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

function initializeModel(
  scene: THREE.Group,
  width: number,
  scrollProgress: number,
  material: THREE.MeshStandardMaterial
) {
  scene.scale.set(3, 3, 3);
  scene.position.set(width * 0.2, scrollProgress * 100, 0);
  scene.rotation.set(
    degreesToRadians(0),
    degreesToRadians(0),
    degreesToRadians(0)
  );

  scene.traverse((child) => {
    if (child instanceof THREE.Mesh) {
      child.material = material;
      child.frustumCulled = true;
    }
  });
}

function Model() {
  const gltf = useLoader(GLTFLoader, "/Logo2.glb");
  const modelRef = useRef<THREE.Object3D | null>(null);
  const { scrollProgress } = useScrollContext();
  const { viewport } = useThree();
  const { width } = viewport;

  const material = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        color: "white",
        metalness: 0.8,
        roughness: 0.3,
      }),
    []
  );

  useEffect(() => {
    if (gltf.scene) {
      initializeModel(gltf.scene, width, scrollProgress, material);
    }
    return () => {
      gltf.scene?.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.geometry.dispose();
          if (child.material instanceof THREE.Material) {
            child.material.dispose();
          }
        }
      });
    };
  }, [gltf, width, material]);

  const position: [number, number, number] = useMemo(
    () => [width * 0.2, scrollProgress * 100, 0],
    [width, scrollProgress]
  );

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y = scrollProgress * Math.PI * 4;
      modelRef.current.position.set(...position);
    }
  });

  return (
    <>
      <LightControlsPlatform />
      <primitive object={gltf.scene} ref={modelRef} />
    </>
  );
}

export default Model;
