import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { DirectionalLight, AmbientLight, PointLight } from "three";
import * as THREE from "three";
export default function LightControlsPlatform() {
  const { scene, size } = useThree();

  const createLights = (scene: THREE.Scene) => {
    const ambientLight = new AmbientLight(0xffffff, 0.1);
    const directionalLight = new DirectionalLight(0xffffff, 0.2);
    directionalLight.position.set(10, 10, 5);

    const pointLight = new PointLight(0x1300ff, 400, 100, 2);
    pointLight.position.set(15, 15, 7);

    scene.add(ambientLight, directionalLight, pointLight);

    return { ambientLight, directionalLight, pointLight };
  };



  useEffect(() => {
    const { ambientLight, directionalLight, pointLight } = createLights(scene);

    let animationFrame: number;

    const handleMouseMove = (event: MouseEvent) => {
      if (animationFrame) return;
      animationFrame = requestAnimationFrame(() => {
        const x = (event.clientX / size.width) * 2 - 1;
        const y = -(event.clientY / size.height) * 2 + 1;

        directionalLight.position.set(x * 10, y * 10, 5);
        pointLight.position.set(x * 15, y * 15, 7);

        animationFrame = 0;
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      scene.remove(ambientLight, directionalLight, pointLight);

    };
  }, [scene, size]);

  return null;
}
