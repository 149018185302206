import styled from "styled-components";

export const FrameBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 478px;
  height: 711px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 34.46%,
      rgba(255, 255, 255, 0.2) 104.94%
    ),
    url(Wrinkled-Glass-Texture.jpg);
  box-shadow: inset -1px 2px 4px rgba(255, 255, 255, 0.25);
  filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(9px);
  padding: 16px;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    padding: 16px;
  }

  @media (max-width: 480px) {
    padding: 16px;
    width: 100%;
  }
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  gap: 42px;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 350px;
  gap: 8px;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: white;
  margin-bottom: 20px;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

export const Subtitle = styled.h3`
  color: #afafaf;
  margin-bottom: 20px;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

export const Input = styled.input<{ $hasError?: boolean }>`
  width: 100%;
  padding: 12px;
  margin-bottom: 5px;
  border: ${(props) => (props.$hasError ? "1px solid red" : "1px solid #ccc")};
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 12px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  height: 100px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
    height: 80px;
  }

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 12px;
    height: 60px;
  }
`;

export const SubmitButton = styled.button`
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 8px;
  }
`;

export const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

export const SuccessBox = styled.div`
  text-align: center;
  color: white;
  padding: 16px;

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

export const SuccessTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

export const SuccessMessage = styled.p`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
