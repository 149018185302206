import { Canvas } from "@react-three/fiber";
import { useEffect } from "react";
import CameraControls from "./CameraControls";
import { SceneContainer } from "../styles/styledComponents";
import React from "react";
import BackgroundControls from "./BackgroundControls";


export default function Scene({
  children,
  config,
}: {
  children?: React.ReactNode;
  config?: Function;
}) {
  useEffect(() => {
    if (config) config();
  }, [config]);

  return (
    <SceneContainer>
      <Canvas>
        <BackgroundControls />
        <CameraControls />
        {children}
      </Canvas>
    </SceneContainer>
  );
}
