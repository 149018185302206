import React, { useRef } from "react";
import { IconName, IconSize } from "@/constants/icon";
import Icon from "@/components/Library/icon/Icon";
import { FontWeight, TextSize } from "@/constants/text";
import { IconColor, TextColor } from "@/constants/colors";
import Text from "../Library/text/Text";
import styled, { css } from "styled-components";


export const BaseBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border-radius: 16px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 34.46%,
      rgba(255, 255, 255, 0.2) 104.94%
    ),
    url(Wrinkled-Glass-Texture.jpg);
  box-shadow: inset -1px 2px 4px rgba(255, 255, 255, 0.25);
  filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(9px);

  &:hover {
    ${css`
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 34.46%,
          rgba(255, 255, 255, 0.377) 104.94%
        ),
        url(Wrinkled-Glass-Texture.jpg);
      box-shadow: inset -1px 2px 4px rgba(255, 255, 255, 0.25);
    `}
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  color: inherit;
  line-height: 24px;
  text-transform: capitalize;
`;

export const Description = styled.div`
  color: inherit;
  line-height: 24px;
`;
interface Boxsize {
  $size: BoxSize;
}

export const BoardContainer = styled(BaseBox)<Boxsize>`
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  text-align: center;
  perspective: 1000px;
  padding: 32px;
  width: 426px;
  height: 324px;

  @media (max-width: 426px) {
    width: 100%;
    height: 355px;

  }
`;

export const ContentWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;

  transform-style: preserve-3d;
`;

export const TextWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
  gap: 8px;

  transform: translateZ(20px);
`;

export enum BoxSize {
  SMALL = "2",
  MEDIUM = "1",
}

interface BoardProps {
  title: string;
  body?: string;
  size?: BoxSize;
  iconName?: IconName;
}

const Box: React.FC<BoardProps> = ({
  title,
  body,
  iconName,
  size = BoxSize.SMALL,
}) => {
  const boxRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const box = boxRef.current;
    if (!box) return;

    const rect = box.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;

    const rotateX = (-y / rect.height) * 10;
    const rotateY = (x / rect.width) * 10;

    box.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  };

  const handleMouseLeave = () => {
    const box = boxRef.current;
    if (box) {
      box.style.transform = `perspective(1000px) rotateX(0deg) rotateY(0deg)`;
    }
  };

  return (
    <BoardContainer
      $size={size}
      ref={boxRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <ContentWrapper>
        {iconName && (
          <IconWrapper>
            <Icon
              name={iconName}
              size={IconSize.XXLARGE}
              color={IconColor.LIGHT}
            />
          </IconWrapper>
        )}
        <TextWrapper>
          <Text
            size={TextSize.H2}
            $weight={FontWeight.SEMI_BOLD}
            color={TextColor.TEXT_ON_PRIMARY}
          >
            {title}
          </Text>
          <Text
            size={TextSize.H3}
            $weight={FontWeight.LIGHT}
            color={TextColor.SECONDARY_ON_DARK}
          >
            {body}
          </Text>
        </TextWrapper>
      </ContentWrapper>
    </BoardContainer>
  );
};

export default Box;
/* Frame 1 */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: center;
// padding: 8px;
// gap: 8px;

// width: 1280px;
// height: 546px;

// /* Note: backdrop-filter has minimal browser support */
// border-radius: 16px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;
// margin: -1000px 0px;
