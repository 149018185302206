import React from "react";
import Text from "@/components/Library/text/Text";
import { FontWeight, TextSize } from "@/constants/text";
import { TextColor } from "@/constants/colors";
import BoxInteractiveComponent from "./BoxInteractiveComponent";
import styled from "styled-components";
import { SectionContainer } from "@/styles/styledComponents";

import { Canvas } from "@react-three/fiber";

import AimodelingModel from "@/threejs/AiModlingModel";
import { OrbitControls } from "@react-three/drei";
import { useDevice } from "@/hooks/DeviceContext";

interface BoxData {
  title: string;
  body: string;
}

const HomeList: BoxData[] = [
  {
    title: "Static Images to 3D Models",
    body: "Replace multiple static images with a single interactive 3D model, providing a complete view of your product from every angle.",
  },
  {
    title: "Elevate Customer Experience",
    body: "Ideal for online retailers aiming to: improve product displays engage customers minimize return rates",
  },
  {
    title: "Fast and Precise Delivery",
    body: "Harness advanced AI with expert human refinement to deliver high-quality, and budget-friendly 3D models at scale.",
  },
];
export const HomeAiModelingStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 900px;
  width: 100%;
  padding: 32px;
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    padding: 24px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-shrink: 0;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  text-align: center;
  gap: 16px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    gap: 12px;
    max-width: 90%;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 52px;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 144px;
  padding: 16px;


  @media (max-width: 1024px) {
    gap: 72px;
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    gap: 72px;
    margin-bottom: 12px;
  }
`;

export const SceneContainer = styled.div`
  display: flex;
  width: 573px;
  height: 334px;
  overflow: hidden;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    width: 80%;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const HomeAiModelingComponent: React.FC = () => {
  const { deviceType } = useDevice();

  return (
    <SectionContainer>
      <HomeAiModelingStyle>
        <ContentWrapper>
          <TextContainer>
            <Text
              size={ deviceType !== "mobile" ? TextSize.D1:TextSize.H1}
              $weight={FontWeight.BOLD}
              color={TextColor.TEXT_ON_PRIMARY}
            >
              {"AI Driven Modeling"}
            </Text>
            <Text
              size={deviceType !== "mobile" ? TextSize.H2:TextSize.TEXT1}
              $weight={FontWeight.LIGHT}
              color={TextColor.SECONDARY_ON_DARK}
            >
              A revolutionary 3D product display solution to Elevate Your
              Product Showcase
            </Text>
          </TextContainer>
          <SceneContainer>
            <Canvas>
              <ambientLight intensity={0.3} />
              <directionalLight
                position={[5, 10, 5]}
                intensity={1}
                color="white"
                castShadow
              />
              <AimodelingModel />
            </Canvas>
          </SceneContainer>
        </ContentWrapper>
        <ItemsContainer>
          {HomeList.map((item, index) => (
            <BoxInteractiveComponent
              isIntractiv={false}
              key={index}
              title={item.title}
              body={item.body}
            />
          ))}
        </ItemsContainer>
      </HomeAiModelingStyle>
    </SectionContainer>
  );
};

export default HomeAiModelingComponent;
