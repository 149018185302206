import React from "react";
import styled from "styled-components";
import Text from "../Library/text/Text";
import { FontWeight, TextSize } from "../../constants/text";
import Button from "../Library/button/Button";
import { SectionContainer } from "@/styles/styledComponents";
import { ButtonSize, ButtonType, ButtonVariant } from "@/constants/button";
import { TextColor } from "@/constants/colors";
import { useDevice } from "@/hooks/DeviceContext";
import { IconName } from "@/constants/icon";
import { Link } from "react-router-dom";

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 64px;
  /* margin-bottom: 240px; */
  height: 900px;

  @media (max-width: 768px) {
    padding: 32px;
  }
`;

export const HomeTitleStylesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 700px;
  gap: 64px;

  @media (max-width: 768px) {
    width: 100%;
    gap: 24px;
  }
`;

interface ButtonProps {
  $mobile: boolean;
}
export const Buttons = styled.div<ButtonProps>`
  display: flex;
  flex-direction: ${({ $mobile: mobile }) => (mobile ? "column" : "row")};
  width: 100%;
  justify-content: left;
  align-items: left;
  gap: 32px;
`;

const HomePlatformComponent: React.FC = () => {
  const { deviceType } = useDevice();

  return (
    <SectionContainer>
      <TitleContent>
        <HomeTitleStylesContent>
          <Text
            color={TextColor.TEXT_ON_PRIMARY}
            $weight={FontWeight.SEMI_BOLD}
            size={deviceType === "mobile" ? TextSize.D1 : TextSize.TT}
          >
            {"Create 3D immersive websites"}{" "}
          </Text>
          <Text
            color={TextColor.SECONDARY_ON_DARK}
            $weight={FontWeight.LIGHT}
            size={TextSize.H2}
          >
            {
              "Get early access now to a powerful editor for creating parameter based immersive environments"
            }
          </Text>
          <Buttons $mobile={deviceType === "mobile"}>
            <Link to="/enterprise">
              <Button
                size={ButtonSize.SMALL}
                type={ButtonType.PRIMARY}
                variant={ButtonVariant.TERTIARY}
                text="Learn more"
                icon={IconName.CARETRIGHT}
                iconPosition="right"
                fullWidth={deviceType === "mobile"}
              />
            </Link>
          </Buttons>
        </HomeTitleStylesContent>
      </TitleContent>
    </SectionContainer>
  );
};

export default HomePlatformComponent;
