import styled from "styled-components";
import Text from "@/components/Library/text/Text";
import { FontFamily, FontWeight, TextSize } from "@/constants/text";
import { TextColor } from "@/constants/colors";
import { IconName } from "@/constants/icon";
import Box, { BoxSize } from "./BoxComponent";
import React from "react";
import { SectionContainer } from "@/styles/styledComponents";
import { useDevice } from "@/hooks/DeviceContext";

interface BoxData {
  title: string;
  body: string;
  iconName: any;
}

const EnterpriceList: BoxData[] = [
  {
    title: "Model and Product generation",
    body: "Create 3D products with AI, scanning, or modeling. Use pre-built library assets to bring your web space to life.",
    iconName: IconName.CUBEFOCUS,
  },
  {
    title: "E-commerce Capabilities",
    body: "Add product displays, carts, and checkout seamlessly into your 3D environment for a complete shopping experience.",
    iconName: IconName.SHOPPINGBAG,
  },
  {
    title: "Immersive 3D Webspace design tools",
    body: "Customize engaging 3D webspaces with intuitive tools that enhance your brand's online presence.",
    iconName: IconName.PAINTBRUSH,
  },
  {
    title: "Publish into a URL domain of your own",
    body: "Launch your webspace on a Mocart subdomain or custom domain with secure payment integration.",
    iconName: IconName.BRACKETSCURLY,
  },
  {
    title: "Multi-Device Compatibility",
    body: "Ensure flawless performance across all devices, providing users with a seamless and reliable experience.",
    iconName: IconName.DEVICES,
  },
  {
    title: "No-Coding skills required",
    body: "Easily create and manage 3D webspaces using intuitive tools, no coding knowledge required.",
    iconName: IconName.JOYSTICK,
  },
];

const HomeList: BoxData[] = [
  {
    title: "Product generation",
    body: "Create 3D products with advanced scanning, AI integration, or in-house modeling for immersive showcases.",
    iconName: IconName.CUBEFOCUS,
  },
  {
    title: "Enhanced E-commerce & Secure Payments",
    body: "Engage customers with interactive shopping and secure payments for products and services.",
    iconName: IconName.HANDCOINS,
  },
  {
    title: "Tailored for You",
    body: "Custom-built 3D webspaces, meticulously crafted to reflect your brand’s identity and engage customers.",
    iconName: IconName.NEEDLE,
  },
  {
    title: "Publish into a URL domain of your own",
    body: "Launch your webspace on any custom domain, fully integrated with robust and secure payment solutions.",
    iconName: IconName.BRACKETSCURLY,
  },
  {
    title: "Multi-Device Compatibility",
    body: "Ensure flawless performance across all devices, providing users with a seamless and reliable experience.",
    iconName: IconName.DEVICES,
  },
  {
    title: "Virtual/augmented reality",
    body: "Enhance your webspace with AR/VR capabilities, providing customers with immersive product interactions.",
    iconName: IconName.VIRTUALREALITY,
  },
];

export const BoxsStylesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 64px;
  padding: 32px;
  min-height: 900px;
  
  
  @media (max-width: 1024px) {
    gap: 32px;
    padding: 24px;
  }

  @media (max-width: 768px) {
    gap: 32px;
    padding: 16px;
  }
`;



export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 1408px;
  flex-shrink: 0;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    gap: 16px;
    margin-bottom: 16px;
  }

  @media (min-width: 1508px) {
    height: 672px;
  }

  @media (max-width: 1024px) {
    padding: 12px;
  }

  @media (max-width: 768px) {
    padding: 8px;
  }

  @media (max-width: 480px) {
    padding: 4px;
  }
`;

interface BoxsComponentProps {
  home?: boolean;
}

const BoxsComponent: React.FC<BoxsComponentProps> = ({ home }) => {
  const listData = home ? HomeList : EnterpriceList;
  const { deviceType } = useDevice();

  return (
    <SectionContainer>
      <BoxsStylesContent>
        <TextContainer>
          <Text
            size={deviceType !== "mobile" ?  TextSize.D1 : TextSize.H1}
            $family={FontFamily.Poppins}
            $weight={FontWeight.SEMI_BOLD}
            color={TextColor.TEXT_ON_PRIMARY}
          >
            {home
              ? "Build an immersive website"
              : "Immersive 3D web spaces are here"}
          </Text>
        </TextContainer>
          <ItemsContainer>
            {listData.map((item, index) => (
              <Box
                size={home ? BoxSize.MEDIUM : BoxSize.SMALL}
                key={index}
                title={item.title}
                body={item.body}
                iconName={item.iconName}
              />
            ))}
          </ItemsContainer>
      </BoxsStylesContent>
    </SectionContainer>
  );
};
export default BoxsComponent;
