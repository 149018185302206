import React from "react";
import Text from "../Library/text/Text";
import { FontFamily, FontWeight, TextSize } from "../../constants/text";
import styled from "styled-components";
import Button from "../Library/button/Button";
import { SectionContainer } from "@/styles/styledComponents";
import { ButtonSize, ButtonType, ButtonVariant } from "@/constants/button";
import { TextColor } from "@/constants/colors";
import { useDevice } from "@/hooks/DeviceContext";
import { Canvas } from "@react-three/fiber";
import HomeMobileModel from "@/threejs/HomeMobileModel";
import { OrbitControls } from "@react-three/drei";

export const HomeTitleStylesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0px;
  width: 100%;
  min-height: 900px;
  gap: 40px;

  @media (max-width: 768px) {
    min-height: auto;
    width: 100%;
    gap: 24px;
  }
`;

interface ButtonProps {
  $mobile: boolean;
}
export const Buttons = styled.div<ButtonProps>`
  display: flex;
  flex-direction: ${({ $mobile: mobile }) => (mobile ? "column" : "row")};
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 32px;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;
export const SceneContainer = styled.div`
  display: flex;
  width: 573px;
  height: 428px;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 80%;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
const HomeTitleComponent: React.FC = () => {
  const { deviceType } = useDevice();

  return (
    <SectionContainer>
      <HomeTitleStylesContent>
        <Text
          color={TextColor.TEXT_ON_PRIMARY}
          $weight={FontWeight.BOLD}
          $family={FontFamily.Poppins}
          size={deviceType === "mobile" ? TextSize.D1 : TextSize.TT}
        >
          {deviceType !== "mobile"
            ? "Custom 3D\nSolutions for\nyour brand"
            : "Custom 3D Solutions "}
        </Text>

        <Text
          $family={FontFamily.Poppins}
          color={TextColor.SECONDARY_ON_DARK}
          $weight={FontWeight.LIGHT}
          size={deviceType === "mobile" ? TextSize.H3 : TextSize.H2}
        >
          {
            "Transform Your Digital Presence with Mocart’s Immersive 3D Solutions"
          }
        </Text>
        {deviceType === "mobile" ? (
          <SceneContainer>
            <Canvas>
              <ambientLight intensity={0.3} />
              <directionalLight
                position={[5, 10, 5]}
                intensity={1}
                color="white"
                castShadow
              />
              <HomeMobileModel />
            </Canvas>
          </SceneContainer>
        ) : null}
        <Buttons $mobile={deviceType === "mobile"}>
          <Button
            size={ButtonSize.LARGE}
            type={ButtonType.PRIMARY}
            variant={ButtonVariant.SECONDARY}
            text="Take me to a web space"
            fullWidth={deviceType === "mobile"}
            onClick={() => {
              window.location.href = "https://3d.mocart.io";
            }}
          />
          <Button
            size={ButtonSize.LARGE}
            type={ButtonType.PRIMARY}
            variant={ButtonVariant.PRIMARY}
            text="Get early access"
            fullWidth={deviceType === "mobile"}
            onClick={() => {
              const targetElement = document.getElementById("ContactForm");
              if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
              }
            }}
          />
        </Buttons>
      </HomeTitleStylesContent>
    </SectionContainer>
  );
};

export default HomeTitleComponent;
