import React, { useEffect, useState } from "react";
import {
  Form,
  FormBox,
  FrameBox,

  SuccessBox,
  SuccessMessage,
  SuccessTitle,
  TextContainer,
} from "./ContactFormStyles";
import { SectionContainer } from "@/styles/styledComponents";
import Input from "@/components/Library/input/Input";
import { InputMode } from "@/constants/input";
import Textarea from "../Library/input/Textarea";
import Text from "../Library/text/Text";
import { TextColor } from "@/constants/colors";
import { FontFamily, FontWeight, TextSize } from "@/constants/text";
import Button from "../Library/button/Button";
import { useDevice } from "@/hooks/DeviceContext";

const ContactFormComponent: React.FC = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const { deviceType } = useDevice();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);

  const handlePhoneNumberChange = (input: string) => {
    const formattedInput = input.replace(/\D/g, "").slice(0, 10);
    setPhone(formattedInput);
  };

  const validateEmail = (email: string): boolean => {
    return email.includes("@") && email.includes(".");
  };

  const validatePhone = (phone: string): boolean => {
    const phoneRegex = /^\d+$/;
    return phoneRegex.test(phone) && phone.length > 8;
  };

  const validateName = (name: string): boolean => {
    return name.length > 2;
  };

  useEffect(() => {
    if (nameError) setNameError(!validateName(name));
    if (emailError) setEmailError(!validateEmail(email));
    if (phoneError) setPhoneError(!validatePhone(phone));
  }, [name, email, phone, nameError, emailError, phoneError]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let isValid = true;

    if (!name || !validateName(name)) {
      setNameError(true);
      isValid = false;
    }

    if (!email || !validateEmail(email)) {
      setEmailError(true);
      isValid = false;
    }

    if (!phone || !validatePhone(phone)) {
      setPhoneError(true);
      isValid = false;
    }

    if (isValid) {
      try {
        const response = await fetch("https://your-api-endpoint.com", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            name: name,
            email: email,
            phone: phone,
            message: message,
          }).toString(),
        });
        if (response.ok) {
          setSuccess(true);
          setTimeout(() => setSuccessMessage(true), 1000);
        } else {
          console.error("Form submission failed.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <SectionContainer id="ContactForm">
      <FrameBox>
        {!successMessage ? (
          <FormBox>
            <TextContainer>
              <Text
                $family={FontFamily.Poppins}
                $weight={FontWeight.SEMI_BOLD}
                color={TextColor.TEXT_ON_PRIMARY}
                size={deviceType !== "mobile" ? TextSize.D1 : TextSize.H2}
              >
                Contact Us
              </Text>
              <Text
                $weight={FontWeight.LIGHT}
                $family={FontFamily.Poppins}
                color={TextColor.SECONDARY_ON_DARK}
                size={TextSize.H3}
              >
                {
                  "What can Mocart do for you?"
                }{" "}
              </Text>
            </TextContainer>
            <Form onSubmit={onSubmit}>
              <Input
                placeholder="Business name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                mode={nameError ? InputMode.ERROR : InputMode.NORMAL}
                helperText={
                  nameError ? "Please enter a valid name." : undefined
                }
              />

              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mode={emailError ? InputMode.ERROR : InputMode.NORMAL}
                helperText={
                  emailError ? "Please enter a valid email." : undefined
                }
              />

              <Input
                placeholder="Phone number"
                value={phone}
                onChange={(e) => handlePhoneNumberChange(e.target.value)}
                mode={phoneError ? InputMode.ERROR : InputMode.NORMAL}
                helperText={
                  phoneError ? "Please enter a valid phone number." : undefined
                }
              />

              <Textarea
                height={100}
                placeholder="How can we help you?"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button onClick={() => onSubmit} text="Submit" />
            </Form>
          </FormBox>
        ) : (
          <SuccessBox>
            <SuccessTitle>Thank You</SuccessTitle>
            <SuccessMessage>
              Dear {name}, one of our team members will reach out to you as soon
              as possible.
            </SuccessMessage>
          </SuccessBox>
        )}
      </FrameBox>
    </SectionContainer>
  );
};

export default ContactFormComponent;
