import {
  FooterContainer,
  FooterLinks,
  FooterLink,
  FooterText,
} from "./FooterStyles";
import { TextSize, FontFamily } from "@/constants/text";
import { TextColor } from "@/constants/colors";
import Text from "@/components/Library/text/Text";

export default function Footer() {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLink to="/">
          {/* <Text
            size={TextSize.H3}
            $family={FontFamily.Poppins}
            color={TextColor.TEXT_ON_PRIMARY}
          >
            About US
          </Text> */}
        </FooterLink>
        <a
          href="../../TermsofUseMocart.pdf" // Adjust the relative path as needed
          target="_blank" // Open the PDF in a new tab
          rel="noopener noreferrer" // For security
          style={{ textDecoration: "none" }} // Optional: match the styles of FooterLink
        >
          <Text
            size={TextSize.H3}
            $family={FontFamily.Poppins}
            color={TextColor.TEXT_ON_PRIMARY}
          >
            Terms of Use
          </Text>
        </a>
        <a
          href="../../PrivacyPolicyMocart.pdf" // Adjust the relative path as needed
          target="_blank" // Open the PDF in a new tab
          rel="noopener noreferrer" // For security
          style={{ textDecoration: "none" }} // Optional: match the styles of FooterLink
        >
          <Text
            size={TextSize.H3}
            $family={FontFamily.Poppins}
            color={TextColor.TEXT_ON_PRIMARY}
          >
            Privacy
          </Text>
          </a>
      </FooterLinks>

      <FooterText>
        <Text
          size={TextSize.H3}
          $family={FontFamily.Poppins}
          color={TextColor.TEXT_ON_PRIMARY}
          $underline
        >
          All Rights Reserved, Mocart.ltd 3D Technologies 2023{" "}
        </Text>
      </FooterText>
    </FooterContainer>
  );
}
