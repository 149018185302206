
import { useEffect, useRef, useState, useMemo, Suspense } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { Html, OrbitControls, Environment } from "@react-three/drei";
import styled from "styled-components";
import { useDevice } from "@/hooks/DeviceContext";

const HoverText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  pointer-events: painted;
  transition: opacity 0.3s ease;
`;

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

const disposeScene = (scene: THREE.Group) => {
  scene.traverse((child) => {
    if (child instanceof THREE.Mesh) {
      if (child.geometry) child.geometry.dispose();
      if (Array.isArray(child.material)) {
        child.material.forEach((mat) => {
          if (mat) mat.dispose();
        });
      } else if (child.material) {
        child.material.dispose();
      }
    }
  });
};


function AimodelingModel() {
  const gltf = useLoader(GLTFLoader, "/canarian_cafe_-_coffee_machine.glb");
  const modelRef = useRef<THREE.Object3D | null>(null);
  const touchTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { deviceType } = useDevice();
  const [controlsEnabled, setControlsEnabled] = useState(
    deviceType !== "mobile"
  );

  const handleTouchStart = () => {
    touchTimerRef.current = setTimeout(() => setControlsEnabled(true), 1000);
  };

  const handleTouchEnd = () => {
    if (touchTimerRef.current) {
      clearTimeout(touchTimerRef.current);
      touchTimerRef.current = null;
    }
    setControlsEnabled(false);
  };

  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.scale.set(1.2, 1.2, 1.2);
      gltf.scene.position.set(0, 0, 0);
      gltf.scene.rotation.set(
        degreesToRadians(0),
        degreesToRadians(250),
        degreesToRadians(0)
      );

      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
    }
  }, [gltf.scene]);


  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    if (modelRef.current) {
      modelRef.current.position.y = -1.9 + Math.sin(elapsedTime) * 0.1;
      modelRef.current.rotation.y = elapsedTime * 0.1;
    }
  });


  useEffect(() => {
    return () => {
      if (gltf.scene) {
        disposeScene(gltf.scene);
      }
    };
  }, [gltf.scene]);
  
  return (
    <>
      <Environment environmentIntensity={0.5} files="/rosendal_park_sunset_4k.hdr" background={false} />
      {deviceType === "mobile" && (
        <Html>
          <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            {!controlsEnabled && <HoverText>Hold and Drag</HoverText>}
          </div>
        </Html>
      )}
      {controlsEnabled && (
        <OrbitControls enablePan={false} enableZoom={false} />
      )}

      <Suspense fallback={null}>
        <primitive object={gltf.scene} ref={modelRef} />
      </Suspense>
    </>
  );
}

export default AimodelingModel;
