import React from "react";
import { IconName, IconSize } from "@/constants/icon";
import Icon from "@/components/Library/icon/Icon";
import { FontWeight, TextSize } from "@/constants/text";
import { IconColor, TextColor } from "@/constants/colors";
import Text from "../Library/text/Text";
import styled, { css } from "styled-components";



export const Title = styled.div`
  color: inherit;
  line-height: 24px;
  text-transform: capitalize;
`;

export const Description = styled.div`
  color: inherit;
  line-height: 24px;
`;

export const BoardContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 0px;
  width: 252px;
  height: 322px;
  border-radius: 4px;

  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    ${css`
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    `}
  }
`;

export const TextWrapper = styled.div`
  height: 50%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
  gap: 8px;
`;
  export const IconWrapper = styled.div`
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 1000px; 
  
    &:hover > div {
      transform: rotateY(360deg);
    }
  `;
  
  export const RotatingIcon = styled.div`
    transition: transform 0.8s ease-in-out; 
    transform-style: preserve-3d;
  `;

export enum BoxSize {
  XS = "xs",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

interface BoardProps {
  title: string;
  body: string;
  iconName: IconName;
}

const BoxGlowingIconComponent: React.FC<BoardProps> = ({
  title,
  body,
  iconName,
}) => {
  return (
    <BoardContainer>
      {iconName && (
        <IconWrapper>
          <RotatingIcon>
            <Icon
              name={iconName}
              size={IconSize.XXLARGE}
              color={IconColor.LIGHT}
            />
          </RotatingIcon>
        </IconWrapper>
      )}
      <TextWrapper>
        <Text
          size={TextSize.H1}
          $weight={FontWeight.SEMI_BOLD}
          color={TextColor.TEXT_ON_PRIMARY}
        >
          {title}
        </Text>
        <Text
          size={TextSize.H3}
          $weight={FontWeight.LIGHT}
          color={TextColor.SECONDARY_ON_DARK}
        >
          {body}
        </Text>
      </TextWrapper>
    </BoardContainer>
  );
};

export default BoxGlowingIconComponent;
