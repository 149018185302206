import React, {  useMemo, useState } from "react";
import styled from "styled-components";
import Button from "@/components/Library/button/Button";
import Input from "@/components/Library/input/Input";
import Text from "@/components/Library/text/Text";
import TripoClient from "@/services/tripo.service";
import {
  ButtonMode,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "@/constants/button";
import { InputSize } from "@/constants/input";
import { TextColor } from "@/constants/colors";
import { FontFamily, FontWeight, TextSize } from "@/constants/text";
import { SectionContainer } from "@/styles/styledComponents";
import { Canvas } from "@react-three/fiber";
import GenerateModel from "@/threejs/GenerateModel";
import { OrbitControls } from "@react-three/drei";
import { useDevice } from "@/hooks/DeviceContext";

const GeneratorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  justify-content: space-between;
  gap: 50px;
  overflow: hidden;
  position: relative;
  padding: 100px;
  @media (max-width: 768px) {
    padding: 8px;
    gap: 16px;
  }
`;

const Mocart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  border-radius: 16px;
  height: 900px;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 34.46%,
      rgba(255, 255, 255, 0.2) 104.94%
    ),
    url(Wrinkled-Glass-Texture.jpg);
  box-shadow: inset -1px 2px 4px rgba(255, 255, 255, 0.25);
  filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(9px);

  margin: 20px;
  padding: 20px;

  @media (max-width: 1024px) {
    height: 468px;
    margin: 16px;
    padding: 16px;
    height: 850px;
  }

  @media (max-width: 768px) {
    margin: 12px;
    padding: 12px;
    height: 740px;
  }

  @media (max-width: 480px) {
    margin: 8px;
    padding: 8px;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  height: auto;
  width: 100%;
  max-width: 585px;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }


`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 16px;
  }

  @media (max-width: 480px) {
    padding: 8px;
  }
`;
export const SceneContainer = styled.div`
  width: 573px;
  height: 428px;
  text-align: center;
  justify-content: center;
  z-index: 1000;
  /* background-color: blanchedalmond; */
`;

const EnterpriseGenerateModelComponent: React.FC = () => {
  const [status, setStatus] = useState<boolean>(true);
  const [modelPreview, setModelPreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [textInput, setTextInput] = useState<string>("");
  const [imageToken, setImageToken] = useState<string | null>(null);
  const [imageFormat, setImageFormat] = useState<string>("png");
  const [isGenerateDisabled, setIsGenerateDisabled] = useState<boolean>(false);
  const { deviceType } = useDevice();

  const tripoClient = useMemo(() => new TripoClient(), []);

  const watchTaskProgress = (taskId: string) => {
    setStatus(false);
    tripoClient.watchTask(taskId, {
      onComplete: (task) => {
        setStatus(true);
        setModelPreview(task.model.url);
      },
      onError: (error) => {
        setError(error.message);
        setStatus(true);
      },
    });
  };

  const generateFromText = async () => {
    setStatus(false);
    try {
      const taskId = await tripoClient.generateFromText(textInput);
      watchTaskProgress(taskId);
    } catch (error: any) {
      setError(error.message);
      setStatus(true);
    }
  };

  const generateFromImage = async () => {
    setStatus(false);
    try {
      if (imageToken) {
        const taskId = await tripoClient.generateFromImage(
          imageToken,
          imageFormat
        );
        watchTaskProgress(taskId);
      }
    } catch (error: any) {
      setError(error.message);
      setStatus(true);
    }
  };

  const uploadImage = async (imageFile: File) => {
    try {
      const token = await tripoClient.uploadImage(imageFile);
      const format = imageFile.name.split(".").pop()?.toLowerCase() || "png";
      setImageToken(token);
      setImageFormat(format);
      setIsGenerateDisabled(true);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleGenerate = () => {
    if (textInput.length > 0) {
      generateFromText();
    } else if (imageToken) {
      generateFromImage();
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value);
    setIsGenerateDisabled(e.target.value.length > 0);
  };

  return (
    <SectionContainer>
      <Mocart>
        <GeneratorContainer>
          <TitleContainer>
            <Text
              size={TextSize.D1}
              $weight={FontWeight.BOLD}
              color={TextColor.TEXT_ON_PRIMARY}
              $family={FontFamily.Poppins}
            >
              Mocart.Ai
            </Text>
            <Text
              size={TextSize.H3}
              $weight={FontWeight.LIGHT}
              color={TextColor.SECONDARY_ON_DARK}
              $family={FontFamily.Poppins}

            >
              Create a model for your product using only an image or a prompt
            </Text>
          </TitleContainer>
          <SceneContainer>
            <Canvas
              shadows
              camera={{
                position: [0, 0, 1],
                fov: 50,
              }}
            >
              <ambientLight intensity={0.3} />
              <directionalLight
                position={[5, 10, 5]}
                intensity={3}
                color="white"
                castShadow
              />
              <OrbitControls enablePan={false} enableZoom={false} />
              <GenerateModel modelUrl={modelPreview} />
              <spotLight
                position={[0, -5, 5]}
                angle={0.5}
                penumbra={0.3}
                intensity={2}
                color="white"
                castShadow
                target-position={[0, 0, 0]}
              />
            </Canvas>
          </SceneContainer>
          {/* {status && (
            <ControlsContainer>
              <Input
                inputSize={InputSize.LARGE}
                value={textInput}
                onChange={handleTextChange}
                placeholder="Enter text for generation"
              />
              <Button
                type={ButtonType.PRIMARY}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                text="Generate"
                onClick={handleGenerate}
                fullWidth={deviceType === "mobile"}

                mode={
                  isGenerateDisabled ? ButtonMode.NORMAL : ButtonMode.DISABLED
                }
              />
            </ControlsContainer>
          )} */}
        </GeneratorContainer>
      </Mocart>
    </SectionContainer>
  );
};

export default EnterpriseGenerateModelComponent;
