
import { useEffect, useRef, useMemo } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useScrollContext } from "@/hooks/ScrollContext";
import { Suspense } from "react";

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

function HomeMobileModel() {
  const gltf = useLoader(GLTFLoader, "/balenciaga_track_3_white.glb");
  const modelRef = useRef<THREE.Object3D | null>(null);
  const { scrollProgress } = useScrollContext();

  const material = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        color: "white",
        metalness: 0.5,
        roughness: 0.5,
      }),
    []
  );

  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.scale.set(3, 3, 3);
      gltf.scene.position.set(0, 0, 0);
      gltf.scene.rotation.set(
        degreesToRadians(20),
        degreesToRadians(150),
        degreesToRadians(30)
      );

      gltf.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = material;
          child.frustumCulled = true;
        }
      });
    }

    return () => {
      gltf.scene?.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.geometry.dispose();
          if (Array.isArray(child.material)) {
            child.material.forEach((mat) => mat.dispose());
          } else if (child.material) {
            child.material.dispose();
          }
        }
      });
    };
  }, [gltf, material]);

  useFrame(() => {
    if (modelRef.current) {
      const rotationY = Math.min(scrollProgress * Math.PI * 40, Math.PI * 2);
      modelRef.current.rotation.y = rotationY;
    }
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <primitive object={gltf.scene} ref={modelRef} />
    </Suspense>
  );
}

export default HomeMobileModel;
