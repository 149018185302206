
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-family: Arial, sans-serif;
    overflow: hidden;
    background-color: black; 
    color: white; 
  }
`;

export default GlobalStyles;
