import React, { useEffect, useRef } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

interface GenerateModelProps {
  modelUrl?: string | null;
}

const GenerateModel: React.FC<GenerateModelProps> = ({ modelUrl }) => {
  const gltf = useLoader(GLTFLoader, modelUrl || "/Plant.glb");
  const modelRef = useRef<THREE.Object3D | null>(null);

  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.scale.set(1, 1, 1);
      gltf.scene.position.set(0, 0, 0);
      gltf.scene.rotation.set(
        degreesToRadians(0),
        degreesToRadians(250),
        degreesToRadians(0)
      );
    }
  }, [gltf]);

  useFrame(({ clock }) => {
    if (modelRef.current) {
     
      modelRef.current.rotation.y +=  0.002;
    }
  });

  return (
      <primitive object={gltf.scene} ref={modelRef} />
  );
};

export default GenerateModel;
