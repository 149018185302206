import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import * as THREE from 'three' 

export default function CameraControls() {
  const { camera } = useThree();

  useEffect(() => {
    
    const perspectiveCamera = camera as THREE.PerspectiveCamera;

    perspectiveCamera.position.set(0, 0,30);
    perspectiveCamera.lookAt(0, 0, 0);
    perspectiveCamera.fov = 50;
    perspectiveCamera.updateProjectionMatrix();
  }, [camera]);

  return null;
}
