import Scene from "../threejs/Scene";
import EnterpriseSceneConfig from "../threejs/config/EnterpriseSceneConfig";

import EnterpriseComponent from "../components/platform/PlatformComponent";
import { EnterprisePageWrapper } from "@/styles/EnterpriseStyles";
import Model from "@/threejs/LogoModel";

export default function PlatformPage() {
  return (
    <EnterprisePageWrapper>
      <Scene config={EnterpriseSceneConfig}>
        <Model />
      </Scene>
      <EnterpriseComponent />
    </EnterprisePageWrapper>
  );
}
