import { HomePageWrapper } from "@/styles/HomeStyles";
import HomeSceneConfig from "../threejs/config/HomeSceneConfig";
import Scene from "../threejs/Scene";
import HomeComponents from "@/components/home/HomeComponent";
import Models from "@/threejs/Models";
import LightControlsHome from "@/threejs/LightControlsHome";
import { useDevice } from "@/hooks/DeviceContext";
import { Environment } from "@react-three/drei";

export default function Home() {
  const { deviceType } = useDevice();

  return (
    <HomePageWrapper>
      <Scene config={HomeSceneConfig}>
        <LightControlsHome />
        <Environment
        files="/rosendal_park_sunset_4k.hdr"
        environmentIntensity={0.000001}
        background={false}
      />
        {deviceType === "desktop" ? <Models /> : null}
      </Scene>
      <HomeComponents />
    </HomePageWrapper>
  );
}
