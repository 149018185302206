import React from "react";
import styled from "styled-components";
import { useScrollContext } from "@/hooks/ScrollContext";
import Icon from "./icon/Icon";
import { IconName, IconSize } from "@/constants/icon";

export const ScrollToTopButtonWrapper = styled.div<{ $visible: boolean }>`
  display: ${({ $visible: visible }) => (visible ? "block" : "none")};
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; 
`;

const ScrollToTopButton: React.FC = () => {
  const { scrollProgress, targetRef } = useScrollContext();

  const handleScrollToTop = () => {
    if (targetRef?.current) {
      targetRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <ScrollToTopButtonWrapper $visible={scrollProgress > 0}>
      <Icon
        name={IconName.ArrowUp}
        size={IconSize.LARGE}
        onClick={handleScrollToTop}
      />
    </ScrollToTopButtonWrapper>
  );
};

export default ScrollToTopButton;
